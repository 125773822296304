import React from "react";
import { Link } from "gatsby";
import { Nav } from "react-bootstrap";
import "./menu.css";
import logo from "../img/journey-fs-logo.png";

export default () => (
  <div className="navigation">
    <div class="ml-auto nav">
      <div class="nav_content">
        <div class="nav-brand-side">
          <img alt="logo" className="img-fluid" src={logo} />
        </div>
      </div>
    </div>
    <Nav className="flex-column">
      <Nav.Item>
        <Link to="/">Home</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/our-services">Our Services</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/our-process">Our Process</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/who-we-are">Who We Are</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/documents-to-gather">Documents to Gather</Link>
      </Nav.Item>
      <Nav.Item>
        <a
          rel="noopener noreferrer"
          href="https://myaccountviewonline.com/AccountView/"
          target="_blank"
        >
          Login to LPL Account
        </a>
      </Nav.Item>
      <Nav.Item>
        <a
          rel="noopener noreferrer"
          href="https://www.lpl.com/about-Us.html"
          target="_blank"
        >
          About LPL Financial
        </a>
      </Nav.Item>
      <Nav.Item>
        <a href="/resources">Resources</a>
      </Nav.Item>
      {/* <Dropdown as={NavItem}>
        <Dropdown.Toggle as={Nav.Link}>Dropdown</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item>Hello there!</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown as={NavItem}>
        <Link variant="success" className="float-left" to="/">
          Dropdown Split Button
        </Link>
        <Dropdown.Toggle split id="dropdown-split-basic" />
        <Dropdown.Menu>
          <Dropdown.Item hred="#/action-1">Action</Dropdown.Item>
          <Dropdown.Item hred="#/action-2">Another action</Dropdown.Item>
          <Dropdown.Item hred="#/action-3">Something else</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown> */}
    </Nav>
  </div>
);
