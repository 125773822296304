import React from 'react'
// import { Link } from 'gatsby'
// import { rhythm, scale } from '../utils/typography'
import 'bootstrap/dist/css/bootstrap.css'
import Header from './Header'
import '../components/styles.css'
import '../components/Contact-Form-Clean.css'
import '../components/updated-side-menu.css'
import '../components/updated-side-menu-1.css'
import 'font-awesome/css/font-awesome.css'

import '../components/Simple-Slider.css'
import lpl from '../img/lpl-logo.png'
// import idfa from '../img/idfa-logo.png'
import cfp from '../img/cfp-logo-black.png'
// import logo from '../img/journey-logo.png'
import swish from '../img/swish-white.png'
import logo2 from '../img/journey-financial-logo.png'

class Layout extends React.Component {
  render() {
    const { children } = this.props
    // const rootPath = `${__PATH_PREFIX__}/`
    let header

    return (
      <div>
        <Header />
        <div>
          {header}
          {children}
          <footer>
            <div id="contact" class="info float-center">
              <div class="container">
                <div class="row">
                  <div class="col-md-4 text-center">
                    <img alt="lpl" class="contact-img" src={lpl} />
                    <div class="row">
                      <div class="col text-right">
                        <img alt="cfp" class="contact-img mb-0" src={cfp} />
                      </div>
                      <div class="col text-left text-black">
                        <h4>Professional</h4>
                      </div>
                    </div>
                    <div className="disclosure text-white text-justify">
                      Journey Financial Services and LPL Financial are separate
                      entities. Securities and advisory services offered through
                      LPL Financial, a registered investment advisor. Member{' '}
                      {''}
                      <a
                        href="http://www.finra.org/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        FINRA
                      </a>{' '}
                      /&nbsp;
                      <a
                        href="https://www.sipc.org/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        SIPC
                      </a>
                    </div>
                    <br />
                    <div className="disclosure text-white text-justify">
                      The LPL Financial registered representative(s) associated
                      with this website may discuss and/or transact business
                      only with residents of the states in which they are
                      properly registered or licensed. No offers may be made or
                      accepted from any resident of any other state.{' '}
                      {/*<a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://www.finra.org/"
                      >
                        FINRA
                      </a>{' '}
                      and{' '}
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://www.sipc.org/"
                      >
                        SIPC
                      </a>
                      .*/}
                    </div>
                  </div>
                  <div class="col-md-4 text-center footer-nav" method="post">
                    <img
                      alt="logo2"
                      className="journey-img w-50 pb-3"
                      src={logo2}
                    />
                    <br />
                    <a
                      className="service-p"
                      href="https://myaccountviewonline.com/AccountView/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Login to LPL Account
                    </a>
                    <br />
                    <a
                      className="service-p"
                      rel="noopener noreferrer"
                      href="https://www.lpl.com/about-Us.html"
                      target="_blank"
                    >
                      About LPL Financial
                    </a>
                    <br />
                    <a
                      className="service-p"
                      rel="noopener noreferrer"
                      href="https://www.lpl.com/CRS"
                      target="_blank"
                    >
                      LPL Financial Form CRS
                    </a>
                  </div>
                  <div class="col-md-4 text-white text-center">
                    <p class="service-p">
                      1880 Office Club Pointe, Suite 201
                      <br />
                      Colorado Springs, CO 80920
                    </p>

                    <a class="service-p" href="tel:7192728008">
                      719-272-8008
                    </a>
                    <a class="service-p" href="mailto:laura.cavanaugh@lpl.com">
                      <br />
                      laura.cavanaugh@lpl.com
                    </a>
                    <div class="swish text-center">
                      <img alt="swish" src={swish} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-white py-4 footer">
              <div class="container">
                <div class="row">
                  <div class="col-md-6 col-sm-12 text-left">
                    <span class="spanYear">
                      © {new Date().getFullYear()} Journey Financial Services.
                      All Rights Reserved.
                      <br />
                    </span>
                  </div>
                  <div class="col-md-6 col-sm-12 text-right">
                    <span class="spanYear">
                      Designed and Powered by &nbsp;
                      <a
                        rel="noopener noreferrer"
                        class="footer-link"
                        target="_blank"
                        alt="WebriQ"
                        href="https://webriq.com"
                      >
                        WebriQ
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    )
  }
}

export default Layout
