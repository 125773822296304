import React from 'react'
import { Link } from 'gatsby'
import Sticky from 'react-sticky-el'
import SideBarMenu from './Sidebar'
import styl from './header.module.css'
import logo from '../img/journey-financial-logo.png'

const Header = () => (
  <header>
    <Sticky
      className={styl.stickyWrapper}
      stickyClassName={styl.isSticky}
      stickyStyle={{ transform: 'unset', zIndex: '2' }}
    >
      <div className={`bg-primary ${styl.menuContainer}`}>
        <div className="container">
          <div className="row align-items-center">
            <div class="col-9 col-md-5">
              <div class="nav-brand">
                <Link aria-current="page" class="" href="/">
                  <img className="w-50" alt="logo" src={logo} />
                </Link>
              </div>
            </div>
            <div className="col-2 col-md-6 d-none-mobile">
              <div class="mt-3 mb-3 float-right row">
                <div class="container-2 col-md-7">
                  <div class="atf-cta mt-4">
                    <p className="text-white">
                      Check the background of investment professionals
                      associated with this site on FINRA’s{' '}
                      <a
                        href="http://brokercheck.finra.org/Individual/Summary/5904194"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white text-underline"
                      >
                        {/*<img
                        className="finra"
                        src="//www.finra.org/themes/custom/finra_bootstrap_sass/images/bc_badge_style_5.svg"
                      />*/}
                        Broker Check
                      </a>
                    </p>
                  </div>
                </div>
                <div class="container-2 col-md-5">
                  <div class="atf-cta mt-4">
                    <a
                      class="bttn2"
                      href="https://getstarted.lpl.com/autopilot"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i class="fa fa-leaf" />
                      &nbsp;Become A Client
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-2 col-md-1 mobile-burger-margin">
              <SideBarMenu />
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </header>
)

export default Header
